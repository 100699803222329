import './App.css'
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom'
import PocketBase, { ListResult, RecordModel } from 'pocketbase'
import { useEffect, useState } from 'react'

const pb = new PocketBase('https://take-title.pockethost.io')

const Center = ({ children }: React.PropsWithChildren) => (
  <div className='min-h-screen flex justify-center items-center p-2'>
    {children}
  </div>
)

const Index = () => <Center>Survey</Center>

const ANSWER_SELECTED = 'bg-indigo-900 text-indigo-50 border-indigo-900'
const ANSWER_UNSELECTED = 'bg-indigo-50 text-indigo-900 border-indigo-600'

const Question = ({
  title,
  min_tooltip,
  max_tooltip,
  selected,
  setSelected,
}: {
  title?: string,
  min_tooltip?: string,
  max_tooltip?: string,
  selected?: number,
  setSelected?: (value: number) => void,
}) => (
  <div>
    <h3 className='text-xl pb-4 sm:pb-6'>{ title }</h3>
    <div className='flex justify-between'>
    {
      Array.from({ length: 11 }).map((n, index) => (
        <div
          key={index}
          className={`
            border rounded
            text-xs  max-w-6 h-6
            sm:max-w-10 sm:h-10 sm:text-base
            w-full flex justify-center items-center
            select-none cursor-pointer
            ${selected === (-5 + index) ? ANSWER_SELECTED : ANSWER_UNSELECTED}
          `}
          onClick={() => setSelected && setSelected(-5 + index)}
        >
          {-5 + index}
        </div>
      ))
    }
    </div>
    <div className='flex justify-between pt-2 text-sm text-indigo-900'>
      <div>{min_tooltip}</div>
      <div>{max_tooltip}</div>
    </div>
  </div>
)

const Survey = () => {
  const { surveyId, respondentId } = useParams()
  const [questions, setQuestions] = useState<ListResult<RecordModel>>()
  const [responses, setResponses] = useState<any>({})
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [success, setSuccess] = useState(false)

  const currentQuestion = questions && questions.items && questions.items[currentQuestionIndex]

  useEffect(() => {
    (async () => {
      if (surveyId) {
        try {
          setQuestions(await pb.collection('questions').getList(1, 100, {
            filter: `survey = "${surveyId}"`,
            expand: 'survey',
            sort: 'order',
          }))
        } catch (e) {
          console.log(e)
          setError('Не удалось загрузить вопросы')
        }

        setLoading(false)
      }
    })()
  }, [surveyId, respondentId])

  useEffect(() => {
    if (!questions || !questions.items || !questions.items.length) {
      return
    }

    if (!respondentId) {
      return
    }

    if (currentQuestionIndex >= questions.items.length) {
      setSaving(true);

      (async () => {
        try {
          const surveyId = questions?.items[0]?.expand?.survey?.id

          await pb.collection('responses').create({
            respondent: respondentId,
            survey: surveyId,
            answers: responses,
          })
        } catch (e) {
          console.error(e)
          setError('Не удалось сохранить ответы')
        }

        setSaving(false)
        setSuccess(true)
      })()
    }
  }, [currentQuestionIndex, questions, respondentId, responses])

  const totalQuestions = questions?.items.length || 0
  const totalAnsweredQuestions = Object.keys(responses).length
  const canGoPrev = currentQuestionIndex > 0
  const canGoNext = currentQuestionIndex < totalQuestions - 1 && currentQuestionIndex < totalAnsweredQuestions
  const canFinish = totalAnsweredQuestions === totalQuestions

  const prevBtnClass = canGoPrev ? 'text-slate-600 cursor-pointer' : 'text-slate-300'
  const nextBtnClass = canGoNext ? 'text-slate-600 cursor-pointer' : 'text-slate-300'

  const goPrev = () => canGoPrev && setCurrentQuestionIndex(currentQuestionIndex - 1)
  const goNext = () => (canGoNext || canFinish) && setCurrentQuestionIndex(currentQuestionIndex + 1)

  const bigNextBtnClass = (
    canGoNext || canFinish
      ? 'bg-indigo-900 text-white py-2 px-6 rounded'
      : 'bg-slate-500 text-white py-2 px-6 rounded'
  )

  if (error) {
    return <Center>{error}</Center>
  }

  if (success) {
    return <Center>Ответы сохранены</Center>
  }

  if (loading) {
    return <Center>Загружаются вопросы, подождите</Center>
  }

  if (saving) {
    return <Center>Сохраняются ответы, подождите</Center>
  }

  if (currentQuestionIndex >= totalQuestions) {
    return <Center>Результат</Center>
  }

  return (
    <Center>
      <div className='max-w-xl w-full'>
        {
          currentQuestion && (
            <Question
              title={currentQuestion.title}
              min_tooltip={currentQuestion.min_tooltip}
              max_tooltip={currentQuestion.max_tooltip}
              selected={responses[currentQuestion.id]}
              setSelected={(value) => {
                setResponses({
                  ...responses,
                  [currentQuestion.id]: value,
                })
              }}
            />
          )
        }
        <div className='pt-8 flex justify-between items-center'>
          <button
            disabled={!canGoNext && !canFinish}
            className={bigNextBtnClass}
            onClick={goNext}
          >Далее</button>
          <div className='flex'>
            <strong className={prevBtnClass} onClick={goPrev}>&lt;</strong>
            <span className='text-slate-600 px-2'>{currentQuestionIndex + 1}/{totalQuestions}</span>
            <strong className={nextBtnClass} onClick={goNext}>&gt;</strong>
          </div>
        </div>
        </div>
    </Center>
  )
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/'>
          <Route index element={<Index />} />
          <Route path='/:surveyId' element={<Survey />} />
          <Route path='/:surveyId/:respondentId' element={<Survey />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
